@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
